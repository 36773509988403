import React, { useState } from "react";
import {
  Card,
  CardHeader,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Chip,
  Grid
} from "@material-ui/core";
import gql from "graphql-tag";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useQuery } from "react-apollo";
import CenteredProgress from "../../common/CenteredProgress";
import {
  WebhookAttempts,
  WebhookAttempts_webhookAttempts_items
} from "../../../graphql/WebhookAttempts";
import moment from "moment";
import { WebhookById_webhookById } from "../../../graphql/WebhookById";
import { jsonPrettyPrint } from "../../../utils/jsonPrettyPrint";

const query = gql`
  query WebhookAttempts($webhookId: ID!) {
    webhookAttempts(webhookId: $webhookId) {
      items {
        id
        eventType
        eventId
        created
        status
        httpStatus
        requestBody
        responseBody
      }
    }
  }
`;

const useRowStyles = makeStyles(theme => ({
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  expandedCell: {
    padding: theme.spacing(2)
  }
}));

interface RowProps {
  webhookAttempt: WebhookAttempts_webhookAttempts_items;
}

const WebhookAttemptRow: React.FC<RowProps> = props => {
  const { webhookAttempt } = props;
  const classes = useRowStyles(props);
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        role={"button"}
        key={webhookAttempt.id}
        onClick={() => setOpen(!open)}
        hover
      >
        <TableCell>
          {open ? (
            <ExpandMoreIcon className={classes.icon} />
          ) : (
            <ChevronRightIcon className={classes.icon} />
          )}
        </TableCell>
        <TableCell>
          <Chip size={"small"} label={webhookAttempt.status} />
        </TableCell>
        <TableCell>
          <Typography>
            <code>{webhookAttempt.eventType}</code>
          </Typography>
        </TableCell>
        <TableCell>
          <Typography>
            <code>{webhookAttempt.eventId}</code>
          </Typography>
        </TableCell>
        <TableCell>
          <Typography>
            {moment(webhookAttempt.created).format("DD/MM/YYYY, HH:mm")}
          </Typography>
        </TableCell>
      </TableRow>
      {open && (
        <TableRow>
          <TableCell colSpan={5} className={classes.expandedCell}>
            <Grid container spacing={1}>
              <Grid item container>
                <Grid item xs={3}>
                  <Typography variant={"body2"}>HTTP status code</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant={"body2"}>
                    {webhookAttempt.httpStatus}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container>
                <Grid item xs={3}>
                  <Typography variant={"body2"}>
                    Request to your endpoint
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography>
                    <pre>{jsonPrettyPrint(webhookAttempt.requestBody)}</pre>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container>
                <Grid item xs={3}>
                  <Typography variant={"body2"}>Response</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography>
                    <pre>{jsonPrettyPrint(webhookAttempt.responseBody)}</pre>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.h6.fontWeight
  },
  card: {
    marginBottom: theme.spacing(1)
  }
}));

interface Props {
  webhook: WebhookById_webhookById;
}

const ViewWebhookAttempts: React.FC<Props> = props => {
  const classes = useStyles(props);
  const { webhook } = props;
  const { loading, data } = useQuery<WebhookAttempts>(query, {
    variables: { webhookId: webhook.id }
  });

  if (loading) {
    return <CenteredProgress />;
  }

  const attempts = data ? data.webhookAttempts.items : [];

  return (
    <Card className={classes.card}>
      <CardHeader
        title={"Webhook attempts"}
        classes={{
          title: classes.title
        }}
      />
      <Table size={"small"}>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>Event Type</TableCell>
            <TableCell>Event ID</TableCell>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {attempts &&
            attempts.map(attempt => (
              <WebhookAttemptRow key={attempt.id} webhookAttempt={attempt} />
            ))}
        </TableBody>
      </Table>
    </Card>
  );
};

export default ViewWebhookAttempts;
