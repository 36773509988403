import React from "react";
import { RouteComponentProps } from "@reach/router";
import { withAuth, WithAuth } from "./withAuth";
import { navigate } from "gatsby-link";

interface Props extends WithAuth, RouteComponentProps {
  component: any;
}

interface State {
  authenticated: boolean | null;
}

class PrivateRoute extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      authenticated: null
    };
    this.checkAuthentication().catch(error =>
      console.log("Error checking authentication", error)
    );
  }

  componentDidUpdate() {
    this.checkAuthentication().catch(error =>
      console.log("Error checking authentication", error)
    );
  }

  checkAuthentication = async () => {
    const authenticated = await this.props.auth.isAuthenticated();
    if (authenticated !== this.state.authenticated) {
      this.setState({ authenticated });
    }
  };

  render() {
    const { component: Component, ...rest } = this.props;

    if (this.state.authenticated === null) {
      return null;
    }

    if (!this.state.authenticated) {
      console.log("Not authenticated");
      navigate("/sign-in/");
      return null;
    }

    return <Component {...rest} />;
  }
}

export default withAuth(PrivateRoute);
