import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { WEBHOOKS_QUERY } from "../ListWebhook";
import { UpdateWebhook } from "../../../graphql/UpdateWebhook";

const mutation = gql`
  mutation UpdateWebhook(
    $webhookId: ID!
    $updateWebhookRequest: UpdateWebhookRequest!
  ) {
    updateWebhook(
      webhookId: $webhookId
      updateWebhookRequest: $updateWebhookRequest
    ) {
      id
      url
      events
    }
  }
`;

interface Props {
  className?: any;
  open: boolean;
  webhook: any;
  onClose: () => void;
  [key: string]: any;
}

const useStyles = makeStyles(theme => ({
  root: {}
}));

const ToggleWebhookDialog: React.FC<Props> = props => {
  const { webhook, open, onClose, ...other } = props;
  const classes = useStyles(props);
  const [toggleWebhook, { loading }] = useMutation(mutation, {
    onCompleted: () => onClose(),
    refetchQueries: [{ query: WEBHOOKS_QUERY }],
    awaitRefetchQueries: true
  });

  const updateWebhookRequest = {
    url: webhook.url,
    events: webhook.events,
    enabled: !webhook.enabled
  };

  return (
    <Dialog
      classes={{
        paper: classes.root
      }}
      open={open}
      maxWidth={"sm"}
      fullWidth
      {...other}
    >
      <DialogTitle id="form-dialog-title">
        {webhook.enabled ? "Disable" : "Enable"} webhook endpoint
      </DialogTitle>
      <DialogContent>
        {webhook.enabled ? (
          <Typography>
            This webhook endpoint may be temporarily disabled so that it will
            not receive notifications until it is enabled again.
          </Typography>
        ) : (
          <Typography>
            This webhook endpoint is disabled and no longer receives
            notifications. You can re-enable the webhook endpoint here.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          color={"primary"}
          type="submit"
          disabled={loading}
          onClick={() =>
            toggleWebhook({
              variables: {
                updateWebhookRequest,
                webhookId: webhook.id
              }
            })
          }
        >
          {webhook.enabled ? "Disable" : "Enable"} webhook endpoint
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ToggleWebhookDialog;
