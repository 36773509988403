import BaseWebhookDialog from "./BaseWebhookDialog";
import * as React from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { WEBHOOKS_QUERY } from "../ListWebhook";
import {
  UpdateWebhook,
  UpdateWebhookVariables
} from "../../../graphql/UpdateWebhook";

interface Props {
  open: boolean;
  onClose: () => void;
  initialValues: WebhookDetailsFormValues;
  [key: string]: any;
}

const UPDATE_WEBHOOK_MUTATION = gql`
  mutation UpdateWebhook(
    $webhookId: ID!
    $updateWebhookRequest: UpdateWebhookRequest!
  ) {
    updateWebhook(
      webhookId: $webhookId
      updateWebhookRequest: $updateWebhookRequest
    ) {
      id
      url
      events
    }
  }
`;

const UpdateWebhookDialog: React.FC<Props> = props => {
  const { open, onClose, initialValues } = props;

  const [submit] = useMutation<UpdateWebhook, UpdateWebhookVariables>(
    UPDATE_WEBHOOK_MUTATION,
    {
      onCompleted: () => onClose(),
      refetchQueries: [{ query: WEBHOOKS_QUERY }],
      awaitRefetchQueries: true
    }
  );

  const handleSubmit = (values: WebhookDetailsFormValues) => {
    const updateWebhookRequest = {
      url: values.url,
      events: values.events,
      enabled: values.enabled
    };

    return submit({
      variables: {
        updateWebhookRequest,
        webhookId: initialValues.id
      }
    });
  };

  return (
    <BaseWebhookDialog
      open={open}
      onClose={onClose}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      title={"Update webhook"}
      submitLabel={"Update webhook"}
      successMessage={"Webhook updated"}
    />
  );
};

export default UpdateWebhookDialog;
