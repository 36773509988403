import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { TableBody, TableHead } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import Chip from "@material-ui/core/Chip";
import { Webhooks } from "../../graphql/Webhooks";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DatasetContext } from "../auth/DatasetContext";
import AddWebhookDialog from "./view/AddWebhookDialog";
import Button from "@material-ui/core/Button";
import PageTitleBar from "../common/header/PageTitleBar";
import PaddedLayout from "../common/PaddedLayout";
import { navigate } from "gatsby-link";

export const WEBHOOKS_QUERY = gql`
  query Webhooks {
    webhooks {
      items {
        id
        url
        enabled
        created
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  toolbar: {
    padding: theme.spacing(0, 2)
  },
  title: {
    flexGrow: 1
  },
  webhookRow: {
    cursor: "pointer"
  }
}));

interface Props {}

const ListWebhook: React.FC<Props> = props => {
  const classes = useStyles(props);
  const { sandboxDataset } = useContext(DatasetContext);
  const [dialogOpened, setDialogOpened] = useState(false);
  const { loading, data, refetch } = useQuery<Webhooks>(WEBHOOKS_QUERY);

  useEffect(() => {
    // tslint:disable-next-line: no-floating-promises
    refetch();
  }, [sandboxDataset]);

  const viewWebhook = (webhookId: string) => navigate(`/webhooks/${webhookId}`);

  return (
    <>
      <PageTitleBar title="Webhooks" />
      <PaddedLayout>
        <Paper>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6" className={classes.title}>
              Endpoints
            </Typography>
            <Button variant={"outlined"} onClick={() => setDialogOpened(true)}>
              Add webhook
            </Button>
            <AddWebhookDialog
              open={dialogOpened}
              onClose={() => setDialogOpened(false)}
            />
          </Toolbar>
          {loading ? (
            <Typography align={"center"} component={"div"}>
              <CircularProgress size={16} />
            </Typography>
          ) : (
            <Table size={"small"}>
              <TableHead>
                <TableRow>
                  <TableCell>URL</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Created</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.webhooks.items.map(endpoint => (
                  <TableRow
                    key={endpoint.id}
                    className={classes.webhookRow}
                    onClick={() => viewWebhook(endpoint.id)}
                    role={"button"}
                    hover
                  >
                    <TableCell component="th" scope="row">
                      <Typography>{endpoint.url}</Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {endpoint.enabled ? (
                        <Chip size={"small"} label={"Enabled"} />
                      ) : (
                        <Chip size={"small"} label={"Disabled"} />
                      )}
                    </TableCell>
                    <TableCell>{moment(endpoint.created).fromNow()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Paper>
      </PaddedLayout>
    </>
  );
};

export default ListWebhook;
