import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CircularProgress,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField
} from "@material-ui/core";
import gql from "graphql-tag";
import { useApolloClient } from "react-apollo";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { WebhookSecret } from "../../../graphql/WebhookSecret";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import RegenerateWebhookSecretDialog from "./RegenerateWebhookSecretDialog";
import { WebhookById_webhookById } from "../../../graphql/WebhookById";
import { showMessage } from "../../common/Toast";

const query = gql`
  query WebhookSecret($webhookId: ID!) {
    webhookSecret(webhookId: $webhookId) {
      id
      secret
    }
  }
`;

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.h6.fontWeight
  },
  card: {
    marginBottom: theme.spacing(1)
  },
  action: {
    margin: 0
  },
  button: {
    marginLeft: theme.spacing(1)
  }
}));

interface Props {
  webhook: WebhookById_webhookById;
}

const ViewWebhookSecret: React.FC<Props> = ({ webhook }) => {
  const classes = useStyles();
  const [secret, setSecret] = useState(null);
  const [secretLoading, setSecretLoading] = useState(false);
  const [regenerateDialogOpened, setRegenerateDialogOpened] = useState(false);
  const client = useApolloClient();

  const showSecret = secret !== null;

  const toggleSecretVisibility = async () => {
    if (showSecret) {
      setSecret(null);
    } else {
      try {
        setSecretLoading(true);
        const { data } = await client.query<WebhookSecret>({
          query,
          fetchPolicy: "network-only",
          variables: { webhookId: webhook.id }
        });
        setSecret(data.webhookSecret.secret);
      } finally {
        setSecretLoading(false);
      }
    }
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        title="Signing secret"
        classes={{
          title: classes.title,
          action: classes.action
        }}
        action={
          <Grid container>
            <Button
              className={classes.button}
              variant={"outlined"}
              onClick={() => {
                setSecret(null);
                setRegenerateDialogOpened(true);
              }}
            >
              Regenerate
            </Button>
          </Grid>
        }
      />
      <RegenerateWebhookSecretDialog
        open={regenerateDialogOpened}
        onCompleted={() => {
          setRegenerateDialogOpened(false);
          showMessage("Webhook signing secret regenerated");
        }}
        onClose={() => {
          setRegenerateDialogOpened(false);
        }}
        webhook={webhook}
      />
      <CardContent>
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <TextField
            type={showSecret ? "text" : "password"}
            value={secret ? secret : "********************************"}
            inputProps={{ "aria-label": "signing secret" }}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle secret visibility"
                    onClick={toggleSecretVisibility}
                    disabled={secretLoading}
                  >
                    {secretLoading && <CircularProgress size={24} />}
                    {!secretLoading && showSecret && <VisibilityOff />}
                    {!secretLoading && !showSecret && <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </form>
      </CardContent>
    </Card>
  );
};

export default ViewWebhookSecret;
