import React from "react";
import { Router } from "@reach/router";
import PrivateRoute from "../components/auth/PrivateRoute";
import ListWebhook from "../components/webhook/ListWebhook";
import ViewWebhook from "../components/webhook/view/ViewWebhook";

const Webhooks = () => (
  <>
    <Router>
      <PrivateRoute path="/webhooks/:webhookId" component={ViewWebhook} />
      <PrivateRoute path="/webhooks" component={ListWebhook} />
    </Router>
  </>
);

export default Webhooks;
