import * as React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import gql from "graphql-tag";
import {
  CreateWebhook,
  CreateWebhookVariables
} from "../../../graphql/CreateWebhook";
import { WEBHOOKS_QUERY } from "../ListWebhook";
import { useMutation } from "@apollo/react-hooks";
import BaseWebhookDialog from "./BaseWebhookDialog";

interface Props {
  className?: any;
  open: boolean;
  onClose: () => void;
  [key: string]: any;
}

const useStyles = makeStyles(theme => ({
  root: {}
}));

const CREATE_WEBHOOK_MUTATION = gql`
  mutation CreateWebhook($url: String!, $events: [String!]!) {
    createWebhook(url: $url, events: $events) {
      id
      url
      events
    }
  }
`;

const AddWebhookDialog: React.FC<Props> = props => {
  const { open, onClose, ...other } = props;
  const classes = useStyles(props);

  const [createWebhook] = useMutation<CreateWebhook, CreateWebhookVariables>(
    CREATE_WEBHOOK_MUTATION,
    {
      onCompleted: () => onClose(),
      refetchQueries: [{ query: WEBHOOKS_QUERY }],
      awaitRefetchQueries: true
    }
  );

  const handleSubmit = async (values: WebhookDetailsFormValues) => {
    return await createWebhook({
      variables: { url: values.url, events: values.events }
    });
  };

  const initialValues = {
    url: "https://",
    enabled: true,
    events: [] as string[]
  };

  return (
    <BaseWebhookDialog
      initialValues={initialValues}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={"Add webhook"}
      submitLabel={"Add webhook"}
      successMessage={"Webhook added"}
    />
  );
};

export default AddWebhookDialog;
