import React, { useState } from "react";
import { WebhookById_webhookById } from "../../../graphql/WebhookById";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  MenuItem,
  Button,
  Typography,
  Grid
} from "@material-ui/core";
import Form from "../../common/Form";
import gql from "graphql-tag";
import { useMutation, useQuery } from "react-apollo";
import { EventTypes } from "../../../graphql/EventTypes";
import { Field } from "react-final-form";
import Input from "../../common/TextField";
import {
  SendTestWebhook,
  SendTestWebhookVariables
} from "../../../graphql/SendTestWebhook";
import { jsonPrettyPrint } from "../../../utils/jsonPrettyPrint";

const SEND_TEST_WEBHOOK_MUTATION = gql`
  mutation SendTestWebhook($webhookId: ID!, $eventType: String!) {
    sendTestWebhook(webhookId: $webhookId, eventType: $eventType) {
      id
      eventType
      eventId
      created
      status
      httpStatus
      requestBody
      responseBody
    }
  }
`;

const LIST_EVENT_TYPES = gql`
  query EventTypes {
    eventTypes {
      items
    }
  }
`;

interface Props {
  webhook: WebhookById_webhookById;
  open: boolean;
  onClose: () => void;
  [key: string]: any;
}

const TestWebhookDialog: React.FC<Props> = props => {
  const { webhook, open, onClose } = props;
  const [resultDisplayed, setResultDisplayed] = useState(false);

  const { loading: eventTypesLoading, data: eventTypesData } = useQuery<
    EventTypes
  >(LIST_EVENT_TYPES);

  const [sendTestWebhook, { data: testWebhookData }] = useMutation<
    SendTestWebhook,
    SendTestWebhookVariables
  >(SEND_TEST_WEBHOOK_MUTATION);

  const onSubmit = (values: TestWebhookFormValues) => {
    setResultDisplayed(true);
    return sendTestWebhook({
      variables: {
        webhookId: webhook.id,
        eventType: values.eventType
      }
    });
  };

  const initialValues = {
    eventType:
      eventTypesData && eventTypesData.eventTypes
        ? eventTypesData.eventTypes.items[0]
        : null
  };

  const testWebhookAttempt = testWebhookData
    ? testWebhookData.sendTestWebhook
    : null;

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, form, hasValidationErrors, submitting }) => (
        <Dialog open={open} maxWidth={"sm"} fullWidth>
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">Send test webhook</DialogTitle>
            <DialogContent>
              {eventTypesLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <Field
                    autoFocus
                    name="eventType"
                    component={Input}
                    label="Event type"
                    fullWidth
                    select
                  >
                    {eventTypesData.eventTypes.items.map(event => (
                      <MenuItem key={event} value={event}>
                        {event}
                      </MenuItem>
                    ))}
                  </Field>
                </>
              )}
              {resultDisplayed && testWebhookAttempt && (
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant={"body2"}>Request</Typography>
                    <pre>{jsonPrettyPrint(testWebhookAttempt.requestBody)}</pre>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={"body2"}>Response</Typography>
                    <pre>HTTP Status: {testWebhookAttempt.httpStatus}</pre>
                    <pre>
                      {testWebhookAttempt.responseBody
                        ? jsonPrettyPrint(testWebhookAttempt.responseBody)
                        : '""'}
                    </pre>
                  </Grid>
                </Grid>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  form.reset();
                  setResultDisplayed(false);
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                color={"primary"}
                type="submit"
                disabled={hasValidationErrors || submitting}
              >
                Send Test Webhook
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Form>
  );
};

export default TestWebhookDialog;
