import React from "react";
import { Theme, WithStyles, withStyles } from "@material-ui/core";

const styles = (theme: Theme) => ({
  paddedContent: {
    padding: theme.spacing(3)
  }
});

const PaddedLayout: React.SFC<WithStyles<typeof styles>> = ({
  children,
  classes
}) => {
  return <div className={classes.paddedContent}>{children}</div>;
};

export default withStyles(styles)(PaddedLayout);
