import React, { Component } from "react";
import {
  IconButton,
  Theme,
  Tooltip,
  Typography,
  WithStyles,
  withStyles
} from "@material-ui/core";
import { colors } from "../../../Theme";
import Icon from "../Icon";
import gql from "graphql-tag";
import { Mutation, Query } from "react-apollo";
import { onboardingVisibleQuery } from "./PageStepper";
import { WithDataset, withDataset } from "../../auth/withDataset";

const styles = (theme: Theme) => ({
  titleBar: {
    display: "flex",
    alignItems: "center",
    height: 67,
    backgroundColor: colors.white,
    borderBottom: `3px solid ${colors.orange}`,
    padding: `0 ${theme.spacing(3)}px`
  },
  title: {
    flexGrow: 1,
    fontSize: 30,
    fontWeight: 100
  },
  actions: {
    display: "flex",
    alignItems: "center"
  },
  helpIcon: {
    color: theme.palette.grey[400],
    fontSize: 30
  },
  sampleData: {
    display: "flex",
    alignItems: "center",
    fontSize: 11,
    lineHeight: "20px",
    border: "1px solid",
    borderColor: colors.orange,
    borderRadius: 10,
    color: colors.orange,
    padding: "0 6px"
  },
  toggleOn: {
    width: 10,
    height: 10,
    backgroundColor: colors.orange,
    display: "inline-block",
    borderRadius: 5,
    marginRight: 4
  }
});

const mutation = gql`
  mutation OpenOnboarding {
    openOnboardingStepper @client {
      onboardingStepperVisible
    }
  }
`;

interface Props extends WithStyles<typeof styles>, WithDataset {
  title: string;
}

class PageTitleBar extends Component<Props> {
  render() {
    const { title, classes, sandboxDataset } = this.props;
    return (
      <div className={classes.titleBar}>
        <Typography className={classes.title}>{title}</Typography>
        <div className={classes.actions}>
          <div className={classes.sampleData}>
            <div className={classes.toggleOn} />
            <span>Viewing {sandboxDataset ? "sandbox" : "live"} data</span>
          </div>
          <Query query={onboardingVisibleQuery} fetchPolicy="cache-first">
            {({ data }) => (
              <React.Fragment>
                {data &&
                  data.onboarding &&
                  !data.onboarding.onboardingStepperVisible &&
                  this.helpButton()}
              </React.Fragment>
            )}
          </Query>
        </div>
      </div>
    );
  }

  helpButton = () => (
    <Mutation mutation={mutation}>
      {open => (
        <Tooltip title="Show Getting Started">
          <IconButton
            aria-label="Show Getting Started"
            className={this.props.classes.helpIcon}
            onClick={() => open()}
          >
            <Icon
              icon="question"
              classes={{ root: this.props.classes.helpIcon }}
            />
          </IconButton>
        </Tooltip>
      )}
    </Mutation>
  );
}

export default withDataset(withStyles(styles)(PageTitleBar));
