import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography
} from "@material-ui/core";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { WEBHOOKS_QUERY } from "../ListWebhook";
import Slider from "@material-ui/core/Slider";
import { showMessage } from "../../common/Toast";

const mutation = gql`
  mutation RegenerateSecret(
    $webhookId: ID!
    $regenerateSecretRequest: RegenerateSecretRequest!
  ) {
    regenerateWebhookSecret(
      webhookId: $webhookId
      regenerateSecretRequest: $regenerateSecretRequest
    ) {
      id
    }
  }
`;

interface Props {
  open: boolean;
  webhook: any;
  onClose: () => void;
  onCompleted: () => void;
  [key: string]: any;
}

const useStyles = makeStyles(theme => ({
  root: {},
  slider: {
    padding: theme.spacing(2)
  },
  expiryText: {
    minHeight: 80
  }
}));

const RegenerateWebhookSecretDialog: React.FC<Props> = props => {
  const classes = useStyles();
  const { webhook } = props;
  const { open, onClose, onCompleted, ...other } = props;
  const [expiryHours, setExpiryHours] = useState(0);

  const handleSliderChange = (event: any, newValue: number) => {
    setExpiryHours(newValue);
  };

  const [regenerateSecret, { loading }] = useMutation(mutation, {
    onCompleted: () => onCompleted(),
    onError: () =>
      showMessage("An error occurred regenerating signing secret", true),
    refetchQueries: [{ query: WEBHOOKS_QUERY }],
    awaitRefetchQueries: true
  });

  return (
    <Dialog
      classes={{
        paper: classes.root
      }}
      open={open}
      maxWidth={"sm"}
      fullWidth
      {...other}
    >
      <form>
        <DialogTitle id="form-dialog-title">
          Regenerate signing secret
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            The current signing secret can be expired immediately or delayed for
            up to 24 hours to allow graceful updates to downstream systems.
          </Typography>

          <Typography variant="caption" gutterBottom>
            Expire current secret:
          </Typography>
          <div className={classes.slider}>
            <Slider
              value={expiryHours}
              onChange={handleSliderChange}
              step={null}
              valueLabelDisplay="auto"
              min={0}
              max={24}
              marks={[
                {
                  value: 0,
                  label: "Now"
                },
                {
                  value: 2,
                  label: "2h"
                },
                {
                  value: 4,
                  label: "4h"
                },
                {
                  value: 8,
                  label: "8h"
                },
                {
                  value: 12,
                  label: "12h"
                },
                {
                  value: 24,
                  label: "24h"
                }
              ]}
            />
          </div>
          {expiryHours === 0 && (
            <Typography className={classes.expiryText} gutterBottom>
              Once regenerated the current secret will expire immediately and
              webhook notifications will be signed with only the new secret.
            </Typography>
          )}
          {expiryHours > 0 && (
            <Typography className={classes.expiryText} gutterBottom>
              Once regenerated webhook notifications will include signatures for
              both current and new secrets. After {expiryHours} hours webhook
              notifications will be signed with only the new secret.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onClose();
            }}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={loading}
            onClick={() =>
              regenerateSecret({
                variables: {
                  regenerateSecretRequest: { expiryHours },
                  webhookId: webhook.id
                }
              })
            }
          >
            Regenerate secret
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RegenerateWebhookSecretDialog;
