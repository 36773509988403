import React, { useState } from "react";
import { WebhookById_webhookById } from "../../../graphql/WebhookById";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  makeStyles,
  Typography
} from "@material-ui/core";
import ToggleWebhookDialog from "./ToggleWebhookDialog";
import UpdateWebhookDialog from "./UpdateWebhookDialog";
import CenteredProgress from "../../common/CenteredProgress";
import TestWebhookDialog from "./TestWebhookDialog";
import DeleteWebhookDialog from "./DeleteWebhookDialog";
import { navigate } from "gatsby-link";
import { showMessage } from "../../common/Toast";

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.h6.fontWeight
  },
  card: {
    marginBottom: theme.spacing(1)
  },
  action: {
    margin: 0
  },
  button: {
    marginLeft: theme.spacing(1)
  },
  events: {
    listStyle: "none",
    margin: 0,
    padding: 0
  }
}));

interface Props {
  webhook: WebhookById_webhookById;
}

const ViewWebhookDetails: React.FC<Props> = props => {
  const classes = useStyles(props);
  const { webhook } = props;
  const [updateDialogOpened, setUpdateDialogOpened] = useState(false);
  const [deleteDialogOpened, setDeleteDialogOpened] = useState(false);
  const [toggleDialogOpened, setToggleDialogOpened] = useState(false);
  const [testDialogOpened, setTestDialogOpened] = useState(false);

  if (!webhook) {
    return <CenteredProgress />;
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        title={"Webhook details"}
        classes={{
          title: classes.title,
          action: classes.action
        }}
        action={
          <Grid container>
            <Button
              className={classes.button}
              variant={"outlined"}
              onClick={() => setTestDialogOpened(true)}
            >
              Send test webhook
            </Button>
            <Button
              className={classes.button}
              variant={"outlined"}
              onClick={() => setToggleDialogOpened(true)}
            >
              {webhook.enabled ? "Disable" : "Enable"}
            </Button>
            <Button
              className={classes.button}
              variant={"outlined"}
              onClick={() => setUpdateDialogOpened(true)}
            >
              Update
            </Button>
            <Button
              className={classes.button}
              variant={"outlined"}
              onClick={() => setDeleteDialogOpened(true)}
            >
              Delete
            </Button>
          </Grid>
        }
      />
      <TestWebhookDialog
        open={testDialogOpened}
        onClose={() => setTestDialogOpened(false)}
        webhook={webhook}
      />
      <ToggleWebhookDialog
        open={toggleDialogOpened}
        onClose={() => setToggleDialogOpened(false)}
        webhook={webhook}
      />
      <UpdateWebhookDialog
        open={updateDialogOpened}
        onClose={() => setUpdateDialogOpened(false)}
        initialValues={webhook}
      />
      <DeleteWebhookDialog
        open={deleteDialogOpened}
        webhookId={webhook.id}
        onCompleted={() => {
          setDeleteDialogOpened(false);
          showMessage("Webhook deleted");
          navigate("/webhooks");
        }}
        onClose={() => setDeleteDialogOpened(false)}
      />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Typography>URL</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{webhook.url}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>Status</Typography>
          </Grid>
          <Grid item xs={9}>
            {webhook.enabled ? (
              <Chip size={"small"} label={"Enabled"} />
            ) : (
              <Chip size={"small"} label={"Disabled"} />
            )}
          </Grid>
          <Grid item xs={3}>
            <Typography>Events</Typography>
          </Grid>
          <Grid item xs={9}>
            <ul className={classes.events}>
              {webhook.events.map(event => (
                <li key={event}>
                  <Typography>
                    <code>{event}</code>
                  </Typography>
                </li>
              ))}
            </ul>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ViewWebhookDetails;
