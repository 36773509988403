import React, { useMemo } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { FieldArrayRenderProps } from "react-final-form-arrays";
import { InputLabel, FormHelperText } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { FieldState } from "final-form";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1)
  },
  listContainer: {
    maxHeight: 200,
    overflowY: "auto",
    marginTop: theme.spacing(3),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4
  },
  list: {
    padding: 0
  },
  listItem: {}
}));

interface Props extends FieldArrayRenderProps<string, any> {
  label: string;
  options: string[];
}

interface CheckedValues {
  [key: string]: boolean;
}

const EventSelector: React.FC<Props> = props => {
  const { label, fields, meta, options } = props;
  const classes = useStyles(props);

  const errorState =
    (meta.touched && meta.error) ||
    (meta.submitFailed && !meta.dirtySinceLastSubmit && meta.submitError);

  let errorMessage;
  if (errorState) {
    errorMessage = meta.submitError || meta.error;
  }

  const handleToggle = (
    event: React.ChangeEvent<HTMLInputElement>,
    option: string
  ) => {
    if (event.target.checked) {
      fields.push(option);
    } else {
      const indexToDelete = fields.value.indexOf(option);
      fields.remove(indexToDelete);
    }
  };

  const setCheckedValue = (
    fields: FieldState<String[]>,
    option: string
  ): boolean => {
    if (!fields || !fields.value) return false;
    const found = fields.value.find(value => value === option);
    return !!found;
  };

  const checkedValues: CheckedValues = useMemo(() => {
    return options.reduce((values: CheckedValues, option: string) => {
      console.log("setCheckedValue", option);
      values[option] = setCheckedValue(fields, option);
      return values;
    }, {});
  }, [fields.value, options]);

  return (
    <FormControl error={!!errorState} className={classes.root}>
      <InputLabel shrink>
        {label} {fields.length > 0 && `(${fields.length} selected)`}
      </InputLabel>
      <div className={classes.listContainer}>
        <List className={classes.list} component={"div"} dense>
          {options.map((option, index) => (
            <ListItem key={option} className={classes.listItem}>
              <ListItemIcon>
                <Checkbox
                  id={`event-${option}`}
                  value={option}
                  checked={checkedValues[option]}
                  onChange={event => handleToggle(event, option)}
                  edge="start"
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": option }}
                />
              </ListItemIcon>
              <ListItemText primary={option} />
            </ListItem>
          ))}
        </List>
      </div>
      {meta.touched && errorMessage && (
        <FormHelperText>{errorMessage}</FormHelperText>
      )}
    </FormControl>
  );
};

export default EventSelector;
