const isJson = (data: string) => {
  try {
    JSON.parse(data);
    return true;
  } catch {
    return false;
  }
};

export const jsonPrettyPrint = (json: string) => {
  if (!json) {
    return "";
  }

  if (!isJson(json)) {
    return json;
  }

  return JSON.stringify(JSON.parse(json), null, 2);
};
