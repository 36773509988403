import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { WEBHOOKS_QUERY } from "../ListWebhook";

const mutation = gql`
  mutation DeleteWebhook($webhookId: ID!) {
    deleteWebhook(webhookId: $webhookId)
  }
`;

interface Props {
  className?: any;
  open: boolean;
  webhookId: string;
  onCompleted: () => void;
  onClose: () => void;
  [key: string]: any;
}

const useStyles = makeStyles(theme => ({
  root: {}
}));

const DeleteWebhookDialog: React.FC<Props> = props => {
  const { webhookId, open, onCompleted, onClose, ...other } = props;
  const classes = useStyles(props);
  const [deleteWebhook, { loading }] = useMutation(mutation, {
    onCompleted: () => onCompleted(),
    refetchQueries: [{ query: WEBHOOKS_QUERY }],
    awaitRefetchQueries: true
  });

  return (
    <Dialog
      classes={{
        paper: classes.root
      }}
      open={open}
      maxWidth={"sm"}
      fullWidth
      {...other}
    >
      <DialogTitle id="form-dialog-title">Delete webhook</DialogTitle>
      <DialogContent>
        <Typography>
          After deleting this endpoint no future webhook will be sent to its
          URL.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          color={"primary"}
          type="submit"
          disabled={loading}
          onClick={() =>
            deleteWebhook({
              variables: {
                webhookId
              }
            })
          }
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteWebhookDialog;
