import * as React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Dialog } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Form from "../../common/Form";
import Input from "../../common/TextField";
import { compose, required } from "../../../utils/validators";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import Grid from "@material-ui/core/Grid";
import gql from "graphql-tag";
import EventSelector from "./EventSelector";
import { useQuery } from "@apollo/react-hooks";
import CircularProgress from "@material-ui/core/CircularProgress";
import { EventTypes } from "../../../graphql/EventTypes";
import { ExecutionResult } from "apollo-link";
import { CreateWebhook } from "../../../graphql/CreateWebhook";
import { UpdateWebhook } from "../../../graphql/UpdateWebhook";

interface Props {
  className?: any;
  open: boolean;
  onClose: () => void;
  initialValues?: WebhookDetailsFormValues;
  onSubmit: (
    values: WebhookDetailsFormValues
  ) => Promise<ExecutionResult<CreateWebhook | UpdateWebhook>>;
  title: string;
  submitLabel: string;
  successMessage: string;
  [key: string]: any;
}

const useStyles = makeStyles(theme => ({
  root: {}
}));

const query = gql`
  query EventTypes {
    eventTypes {
      items
    }
  }
`;

const BaseWebhookDialog: React.FC<Props> = props => {
  const {
    open,
    onClose,
    initialValues,
    onSubmit,
    title,
    submitLabel,
    successMessage,
    ...other
  } = props;
  const classes = useStyles(props);

  const { loading: eventTypesLoading, data: eventTypesData } = useQuery<
    EventTypes
  >(query);

  // Added initialValuesEqual={() => true} due to react-final-form-arrays bug:
  // https://github.com/final-form/react-final-form-arrays/issues/61
  return (
    <Form
      initialValuesEqual={() => true}
      initialValues={initialValues}
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators
      }}
      successMessage={successMessage}
      showErrorToasts={true}
    >
      {({ handleSubmit, form, hasValidationErrors, submitting }) => (
        <Dialog
          classes={{
            paper: classes.root
          }}
          open={open}
          maxWidth={"sm"}
          fullWidth
          {...other}
        >
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
              {eventTypesLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <Field
                    autoFocus
                    name="url"
                    component={Input}
                    label="Endpoint URL"
                    placeholder="https://"
                    validate={compose(required("URL is required"))}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                  />
                  <FieldArray
                    name="events"
                    label={"Events"}
                    options={eventTypesData.eventTypes.items}
                    component={EventSelector}
                  />
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Grid
                container
                spacing={2}
                direction={"row-reverse"}
                component={"div"}
              >
                <Grid item>
                  <Button
                    color={"primary"}
                    type="submit"
                    disabled={hasValidationErrors || submitting}
                  >
                    {submitLabel}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color={"primary"}
                    onClick={() => {
                      form.reset();
                      onClose();
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Form>
  );
};

export default BaseWebhookDialog;
