import React from "react";
import {
  CircularProgress,
  Theme,
  WithStyles,
  withStyles
} from "@material-ui/core";
import cx from "classnames";
import { CircularProgressProps } from "@material-ui/core/CircularProgress";

const styles = (theme: Theme) => ({
  root: {
    width: "100%",
    textAlign: "center" as "center",
    padding: theme.spacing(4)
  }
});

interface Props extends WithStyles<typeof styles> {
  className?: string;
  color?: CircularProgressProps["color"];
  style?: object;
}

const CenteredProgress: React.SFC<Props> = ({
  classes,
  className,
  color,
  style
}) => {
  return (
    <div className={cx(classes.root, className)} style={style}>
      <CircularProgress color={color} />
    </div>
  );
};

export default withStyles(styles)(CenteredProgress);
