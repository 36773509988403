import {
  Step as MuiStep,
  StepLabel as MuiStepLabel,
  withStyles,
  WithStyles,
  Omit,
  createStyles,
  Theme
} from "@material-ui/core";
import { StepProps } from "@material-ui/core/Step";
import React from "react";

interface Props
  extends WithStyles<typeof stepStyles>,
    Omit<StepProps, "classes"> {
  label: string;
}

const stepStyles = (theme: Theme) =>
  createStyles({
    stepLabel: {
      zIndex: 1
    },
    stepLabelIcon: {},
    stepLabelInactive: {
      color: theme.palette.grey.A100,

      "&$stepLabelIcon": {
        color: "black"
      }
    },
    label: {
      color: theme.palette.common.black,
      fontWeight: "bold",

      "&$activeLabel": {
        color: theme.palette.common.black,
        fontWeight: "bold"
      }
    },
    activeLabel: {}
  });

export const Step = withStyles(stepStyles)(
  ({ classes, label, ...rest }: Props) => {
    const stepLabel = (
      <MuiStepLabel
        classes={{
          iconContainer: classes.stepLabel,
          label: classes.label,
          active: classes.activeLabel
        }}
        StepIconProps={{
          classes: {
            root: classes.stepLabelInactive,
            active: classes.stepLabelIcon
          }
        }}
      >
        {label}
      </MuiStepLabel>
    );
    return (
      <MuiStep {...rest} completed={false} active={rest.completed}>
        {stepLabel}
      </MuiStep>
    );
  }
);
