import React, { useContext, useEffect, useState } from "react";
import PageTitleBar from "../../common/header/PageTitleBar";
import PaddedLayout from "../../common/PaddedLayout";
import { RouteComponentProps } from "@reach/router";
import CenteredProgress from "../../common/CenteredProgress";
import gql from "graphql-tag";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { WebhookById } from "../../../graphql/WebhookById";
import Typography from "@material-ui/core/Typography";
import { DatasetContext } from "../../auth/DatasetContext";
import { useQuery } from "@apollo/react-hooks";
import ViewWebhookAttempts from "./ViewWebhookAttempts";
import ViewWebhookSecret from "./ViewWebhookSecret";
import ViewWebhookDetails from "./ViewWebhookDetails";

const WEBHOOK_QUERY = gql`
  query WebhookById($id: ID!) {
    webhookById(id: $id) {
      id
      url
      events
      enabled
      created
    }
  }
`;

interface RouteProps {
  webhookId: string;
}

interface Props extends RouteComponentProps<RouteProps> {}

const ViewWebhook: React.FC<Props> = props => {
  const { webhookId } = props;
  const { sandboxDataset } = useContext(DatasetContext);
  const { loading, data, error, refetch } = useQuery<WebhookById>(
    WEBHOOK_QUERY,
    {
      variables: { id: webhookId }
    }
  );

  useEffect(() => {
    // tslint:disable-next-line: no-floating-promises
    refetch();
  }, [sandboxDataset]);

  if (loading) {
    return <CenteredProgress />;
  }

  if (error) {
    console.log("error", JSON.stringify(error));
  }

  const webhook = data ? data.webhookById : null;

  return (
    <>
      <PageTitleBar title="Webhooks" />
      <PaddedLayout>
        {!webhook ? (
          <Card>
            <CardContent>
              <Typography>
                We couldn't find the webhook you were looking for in{" "}
                {sandboxDataset ? "Sandbox" : "Live"} mode.
              </Typography>
            </CardContent>
          </Card>
        ) : (
          <>
            <ViewWebhookDetails webhook={webhook} />
            <ViewWebhookSecret webhook={webhook} />
            <ViewWebhookAttempts webhook={webhook} />
          </>
        )}
      </PaddedLayout>
    </>
  );
};

export default ViewWebhook;
