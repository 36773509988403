import { TextField } from "@material-ui/core";
import { TextFieldProps } from "@material-ui/core/TextField";
import * as React from "react";
import { FieldRenderProps } from "react-final-form";

const Input: React.FC<FieldRenderProps<HTMLElement> & TextFieldProps> = ({
  input: { name, onChange, value, ...restInput },
  meta,
  placeholder,
  helperText,
  classes,
  ...rest
}) => {
  const errorState =
    (meta.touched && meta.error) ||
    (meta.submitFailed && !meta.dirtySinceLastSubmit && meta.submitError);

  let errorMessage;
  if (errorState) {
    errorMessage = meta.submitError || meta.error;
  }

  return (
    <TextField
      {...rest}
      name={name}
      helperText={
        meta.touched && errorMessage ? errorMessage : helperText || " "
      }
      error={!!errorState}
      placeholder={placeholder}
      InputProps={{
        ...(restInput as any),
        ...rest.InputProps
      }}
      onChange={onChange}
      value={value}
    />
  );
};

export default Input;
