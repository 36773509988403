import React, { Component } from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  Card,
  Theme,
  Typography
} from "@material-ui/core";
import cx from "classnames";
import { colors } from "../../../Theme";
import Icon from "../Icon";
import { navigate } from "gatsby";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      cursor: "pointer",
      marginBottom: theme.spacing(1),

      "&:hover": {
        "& $card": {
          transform: "translateY(-1px)",
          boxShadow: "0 2px 5px rgba(50, 50, 50, 0.2)"
        }
      },

      "&:nth-of-type(1)": {
        "& $card": {
          background: colors.primaryDark
        }
      },

      "&:nth-of-type(2)": {
        "& $card": {
          background: colors.primaryLight
        }
      },

      "&:nth-of-type(3)": {
        "& $card": {
          background: colors.secondary
        }
      },

      "&:nth-of-type(4)": {
        "& $card": {
          background: colors.tertiary
        }
      }
    },
    card: {
      color: "white",
      padding: theme.spacing(3),
      display: "flex",
      alignItems: "center",
      transition: "box-shadow, transform 0.2s ease",
      boxShadow: "0 2px 5px rgba(50, 50, 50, 0.0)",
      height: "100%",

      "&:before": {
        boxShadow: "0 0 100px #000000"
      }
    },
    image: {
      width: 48,
      height: 48,
      marginRight: theme.spacing(3),
      flexShrink: 0
    },
    title: {
      fontSize: 18,
      fontWeight: "bold"
    },
    details: {
      fontSize: 12,
      fontWeight: "bold"
    }
  });

interface Props extends WithStyles<typeof styles> {
  title: string;
  description: string;
  image: React.ComponentType<{ className: string }>;
  to: string;
  external?: boolean;
}

export class ClickableCard extends Component<Props> {
  render() {
    const { classes, title, description, image: Image } = this.props;
    return (
      <div className={cx(classes.root)} onClick={this.handleClick}>
        <Card className={cx(classes.card)}>
          <Image className={classes.image} />
          <div>
            <Typography color="inherit" className={classes.title}>
              {title}
            </Typography>
            <Typography color="inherit" className={classes.details}>
              {description}
            </Typography>
          </div>
        </Card>
      </div>
    );
  }

  handleClick = () => {
    if (this.props.external) {
      window.open(this.props.to, "_blank");
    } else {
      navigate(this.props.to);
    }
  };
}

export default withStyles(styles)(ClickableCard);
